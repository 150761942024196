/**
 * function to initialize the session timeout timer
 *
 * @returns an object, containing 2 event listeners. one for
 * the countdown to showing
 *
 * @param showTimeoutAlert function to show the timeout alert to the user
 *
 */
export default function initializeTimeoutCountdown({
  showTimeoutAlert = () => {},
  setShowAlertTimeout = () => {},
  setEndSessionTimeout = () => {},
}) {
  // default timeout length of 20 minutes.
  const timeoutValue = process.env.TIMEOUT || 1000 * 60 * 20;

  const showAlertTimeout = setTimeout(() => {
    showTimeoutAlert(true);
  }, timeoutValue);
  const endSessionTimeout = setTimeout(() => {}, timeoutValue);

  setShowAlertTimeout(showAlertTimeout);
  setEndSessionTimeout(endSessionTimeout);
}
