import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { PaymentOption } from 'api';
import { getQueryParameters } from '../util';

const useApiCheckout = () => {
	const router = useRouter();
	// using asPath instead of query because query is undefined initially (on first render)
	const { token } = getQueryParameters(router.asPath);


	const [isApiCheckout, setIsApiCheckout] = useState(Boolean(token));
	const [guestAuthSuccessful, setGuestAuthSuccessful] = useState(false);


	const doGuestSignon = async token_ => {
		const guestSignOnRes = await PaymentOption.getSignUserWithToken(token_);

		// any response that is actually returned will have a status of 0 anyway
		setGuestAuthSuccessful(!(guestSignOnRes instanceof Error) && guestSignOnRes.status === 0);
	}

	useEffect(() => {
		// anytime the token changes, check if isAPICheckout
		setIsApiCheckout(Boolean(token));
	}, [token]);

	useEffect(() => {
		// if guestAuth is not successful during an API checkout, attempt a guest signon
		if (isApiCheckout && !guestAuthSuccessful) {
			// do guest signon
			doGuestSignon(token);
		}
	}, [isApiCheckout]);

	return {
		guestAuthSuccessful,
		isApiCheckout,
	};
}


export default useApiCheckout;

