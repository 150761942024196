import useBugsnag from 'hooks/useBugsnag';

const client = require('./request/client');
const { notifyBugsnag } = useBugsnag();

const URL = `${process.env.EP_URL}/client_api/category.php`;

const getAll = async () => {
  const requestPayload = {
    request: 'search_categories',
    search_field: 'all',
    search_value: 'all',
    api: '4.1',
    current_page: 0,
  };

  return await client
    .sendRequest(URL, requestPayload)
    .then((response) => {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response } } });

        throw error;
      }
      return response.output;
    })
    .catch((error) => {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response: error.response } } });


      throw error;
    });
};

const getBySrvrtid = async (srvrtid) => {
  const requestPayload = {
    request: 'search_categories',
    search_field: 'srvrtid',
    search_value: srvrtid,
    rms: 'true',
    api: '4.1',
    // api: '4.2',
  };

  return await client
    .sendRequest(URL, requestPayload)
    .then((response) => {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response } } });
      return error;


      }
      if(response.output.length <= 0) {
        return null;
      }
      const category = response.output[0];
      return category.ms;
    })
    .catch((error) => {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response: error.response } } });

      return error;
    });
};

module.exports = {
  getAll,
  getBySrvrtid,
};
