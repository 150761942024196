import useBugsnag from 'hooks/useBugsnag';

const client = require('./request/client');
const { notifyBugsnag } = useBugsnag();

const URL = `${process.env.EP_URL}/client_api/merchant_service.php`;

const getRetailServices = async () => {
  const requestPayload = {
    request:"search_merchant_services",
    rms:true,
    search_value:'TRUE',
    search_field:"retail_srvrtids",
  };

  return await client
    .sendRequest(URL, requestPayload)
    .then((response) => {
        // console.log("response::",response);
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response } } });
      return error;


      }
        return response.output;
    })
    .catch((error) => {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response: error.response } } });
      return error;


    });
};

module.exports = {
 getRetailServices,
  };