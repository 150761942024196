const client = require('./request/client');
import useBugsnag from 'hooks/useBugsnag';
const { notifyBugsnag } = useBugsnag();



const ENDPOINT = `${process.env.EP_URL}/client_api/merchant_landing.php`;

//const ENDPOINT = "http://edmundyeboah.expresspaygh.com/expresspay/website/merchant_landing.php";


const submitMerchantlandingDetails = async (data) => {

    const requestPayload = {
        amountId: data.amount ? data.amount : "",
        trnamt: data.amount ? data.amount : "",
        merchant_service_srvrtid: data.selectedMerchantServiceSrvrtid,
        "service-description": "",
        payacct: data?.phoneNumber,
        description: `${data.firstName} ${data.lastName}`,
        url: `merchant_landing.php?ms=${data?.selectedMerchantServiceSrvrtid}`,
        "denom-srvrtid": data.selectedDenominationId ? data.selectedDenominationId : "",
        phone_number: data?.phoneNumber,
        first_name: data?.firstName,
        last_name: data?.lastName,
        email: data?.email,
        "g-recaptcha-response": data?.g_recaptcha_response
      }
  
        return await client
        .sendRequest(ENDPOINT, requestPayload)
        .then(function (response) {
            console.log("response::",response);
            const isNotAnError =  response.status === 0 || response.status === 10 || response.status === 11;
            if (isNotAnError) {
                return response;
            } else {
              const error = new Error(response.message);
              notifyBugsnag({ error, metaData: { scope: 'API REQUEST' } });
              return error;
            }
        })
        .catch(function (error) {
            console.log("error::",error);
              notifyBugsnag({ error, metaData: { scope: 'API REQUEST' } });
            return error;
        });
        };
  
  export default {
    submitMerchantlandingDetails,
  };