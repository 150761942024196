const client = require('./request/client');
import useBugsnag from 'hooks/useBugsnag';

const URL = `${process.env.EP_URL}/client_api/merchant_service.php`;
const { notifyBugsnag } = useBugsnag();

const findBySrvrtid = async srvrtid => {
  const requestPayload = {
    request: 'search_merchant_services',
    api: '4.0',
    search_field: ['srvrtid'],
    search_value: [`${srvrtid}`],
    display: 'ACTIVE',
    value: true,
  };

  console.log('requestPayload::', requestPayload);

  return await client
    .sendRequest(URL, requestPayload)
    .then(response => {
      console.log('response findBySrvrtid::', response);

      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response } } });
        return error;
      }
      const merchantService = response.output[0];

      if (!merchantService) {
        return new Error('Service not found');
      }
      return merchantService;
    })
    .catch(error => {
      console.log('errorFrom findBySrvrtid::', error);
      notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response: error.response } } });
      return error;
    });
};

const search = async searchTerm => {
  // const requestPayload = {
  //   request: 'search_merchant_services',
  //   api: '4.0',
  //   search_field: 'name',
  //   search_value: searchTerm,
  // };

  const requestPayload = {
    request: 'search_merchant_services',
    api: '4.0',
    search_field: ['name', 'display'],
    search_value: [searchTerm, 'ACTIVE'],
  };

  return await client
    .sendRequest(URL, requestPayload)
    .then(response => {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response } } });
        return [];
      }
      return response.output;
    })
    .catch(error => {
      notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response: error.response } } });
      return error;
    });
};

const fetchAll = async () => {
  const requestPayload = {
    request: 'search_merchant_services',
    api: '4.0',
    search_field: ['display'],
    search_value: ['ACTIVE'],
  };

  return await client
    .sendRequest(URL, requestPayload)
    .then(response => {
      // console.log('response from fetchAll::', response);
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response } } });
        return error;
      }
      const merchantService = response.output;

      if (!merchantService) {
        const error = new Error('Service not found');
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response } } });
        return error;
      }
      return merchantService;
    })
    .catch(error => {
      // console.log('error from fetchAll::', error);
      notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response: error.response } } });
      return error;
    });
};

const fetchLandingPageServicesForMerchant = async merchantSrvrtid => {
  const requestPayload = {
    request: 'search_merchant_services',
    api: '4.0',
    search_field: ['merchant_srvrtid', 'status', 'merchant_landing_display'],
    search_value: [merchantSrvrtid, 'ACTIVE', 'TRUE'],
  };

  return await client
    .sendRequest(URL, requestPayload)
    .then(response => {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response } } });
        return [];
      }
      return response.output;
    })
    .catch(error => {
      notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response: error.response } } });
      return error;
    });
};

module.exports = {
  fetchLandingPageServicesForMerchant,
  findBySrvrtid,
  fetchAll,
  search,
};
