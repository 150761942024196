import { parseCookies, setCookie, destroyCookie } from 'nookies';
import isEqual from 'lodash.isequal';

const titleCase = (string) => {
  const lower = string.toLowerCase();
  return lower.charAt(0).toUpperCase() + lower.slice(1);
};

const capitalize = (string) => string.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());

const makeRandomString = (length) => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';

  // eslint-disable-next-line no-plusplus
  for (let i = length; i > 0; --i) {
    result += chars[Math.round(Math.random() * (chars.length - 1))];
  }
  return result;
};

const stripNonNumericCharacters = (str) => str.replace(/[^0-9]+/g, '');

const getCardIssuerName = (cardNumber) => {
  let cardIssuer = 'UNKNOWN';

  if (cardNumber.startsWith('4')) {
    cardIssuer = 'VISA';
  } else if (cardNumber.startsWith('5')) {
    const re = /^5[1-5]/;
    if (re.test(cardNumber)) {
      cardIssuer = 'MASTERCARD';
    }
  } else if (cardNumber.startsWith('6')) {
    const re = /^(6011|65)/;
    if (re.test(cardNumber)) {
      cardIssuer = 'DISCOVER';
    }
  } else if (cardNumber.startsWith('3')) {
    const re = /^(34|37)/;
    if (re.test(cardNumber)) {
      cardIssuer = 'AMEX';
    }
  }

  return cardIssuer;
};

function formatCardExpiry(input) {
  input = input.replace('/', '');
  function formatMonth(month) {
    let newMonth = month;
    if (month.length === 1 && month > 1) {
      newMonth = `0${month}`;
    } else if (Number(month) > 12) {
      newMonth = month.slice(0, 1);
    }
    return newMonth;
  }
  function formatYear(year) {
    return year ? `/${year}` : '';
  }
  return `${formatMonth(input.slice(0, 2))}${formatYear(input.slice(2))}`;
}

const formatCardNumber = (cardNumber) => {
  if (!cardNumber.length) return '';
  const nonNumericCharactersRe = /[^0-9]/;
  const containsNonNumericCharacters = nonNumericCharactersRe.test(cardNumber);
  if (containsNonNumericCharacters) return cardNumber;

  if (cardNumber.length < 5) return cardNumber;

  return `${cardNumber.slice(0, 4)}  ${formatCardNumber(cardNumber.slice(4))}`;
};

const prep = (expiry) => {
  if (expiry.length === 1) {
    if (expiry === '0' || expiry === '1') {
      return expiry;
    }
    return `0${expiry}`;
  }
  return expiry;
};

const dashboardPaths = {
  myMoney: ['/my-money', '/new-payment-method', '/edit-payment-method'],
  savedAccounts: ['/saved-accounts', '/new-saved-account', '/edit-saved-account'],
  transactions: ['/transaction-history'],
  settings: ['/user-settings'],
  invite: ['/invite-friends'],
};

const BANK_DIRECT_SEND_OPTIONS = [
  {
    value: 'phone_number',
    label: 'Phone Number',
  },
  {
    value: 'bank_account_number',
    label: 'Bank Account Number (Instant Pay)',
  },
];

const getMerchantServicePackages = (service) => {
  const { packages } = service;

  return packages.map((item) => ({
    label: item.name,
    value: item.name,
    denominations: item.output.map((denom) => ({
      label: `${denom.description} - (${denom.ccy} ${denom.amount})`,
      value: denom.srvrtid,
      amount: denom.amount,
    })),
  }));
};

const setClientCookie = (
  name,
  value,
  options = {
    path: '/',
  }
) => {
  setCookie({}, name, value, options);
};

const destroyClientCookie = (
  name,
  options = {
    path: '/',
  }
) => {
  destroyCookie({}, name, options);
};

const parseClientCookies = () => parseCookies({});

const parseServerCookies = (context) => parseCookies(context);

const doNothing = () => { };

const UNAUTHENTICATED_REQUEST_ERRORS = [
  'You are not currently signed into the website.  Kindly signon and retry your request.',
  'Unable to complete the requested action',
  'Insufficient privileges to perform this operation',
  'User is not signed in',
  'You must be logged in to perform this action.',
  '<AUTHTOKEN> invalid',
];

// Component Related
function updateComponentIfUserAndStateHasNotChanged({ prevUser, nextUser, nextState, prevState }) {
  let shouldUpdate = true;

  if (prevUser && nextUser) {
    if (prevUser.srvrtid === nextUser.srvrtid) {
      if (isEqual(nextState, prevState)) {
        shouldUpdate = false;
      }
    }
  }

  return shouldUpdate;
}

function getCookieFromClient(name) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
}

function deleteCookie(name, time = new Date().toUTCString()) {
  document.cookie = name + `=; expires=${time};`;
}

const getQueryParameters = (path) => {
  // path - /page?param=value
  const queryString = path.includes('?') ? path.split('?')[1] : '';

  // queryString - param=value&param2=value2
  return queryString
    .split('&')
    .reduce((acc, string) => {
      const keyValuePair = string.split('=');
      return { ...acc, [keyValuePair[0]]: (keyValuePair.length == 2 ? keyValuePair[1] : 1) };
    }, {});
};

const mapFieldsWithBooleanStringsToProperBooleans = (target) => {
  const changed = {};
  const changeValue = (current) => {
    if (current === 'TRUE') {
      return true;
    }
    if (current === 'FALSE') {
      return false;
    }
    return current;
  };

  for (const prop in target) {
    changed[prop] = changeValue(target[prop]);
  }

  return changed;
};
const mapFieldsWithBooleansToStrings = (target) => {
  const changed = {};
  const changeValue = (current) => {
    if (current === true) {
      return 'TRUE';
    }
    if (current === false) {
      return 'FALSE';
    }
    return current;
  };

  for (const prop in target) {
    changed[prop] = changeValue(target[prop]);
  }

  return changed;

};


const pushToPaymentPage = (data={}, query={}, Router ={}) => {
  console.log("pushToPaymentPageData::", data);
  console.log("pushToPaymentPageQuery::", query);
  if (data.amount) {
    query.amount = data.amount;
  } 
  if(data.selectedPackageId){
    query.packageId = data.selectedPackageId;
  }

  if(data.selectedDenominationId){
    query.denomId = data.selectedDenominationId;
  }
  //add all new query params after amount
  if(data.additionaldata_line1){
    query.additonalData1 = data.additionaldata_line1;
  }
  if(data.additionaldata_line2){
    query.additonalData2 = data.additionaldata_line2;
  }



  let queryString = '';
  const params = Object.keys(query);
  params.forEach((param, index) => {
    queryString += `${param}=${encodeURIComponent(query[param])}`;
    if (index < params.length - 1) {
      queryString += '&';
    }
  });
    Router.push(`/payment?${queryString}`);
  }


export {
  titleCase,
  capitalize,
  makeRandomString,
  stripNonNumericCharacters,
  getCardIssuerName,
  formatCardExpiry,
  formatCardNumber,
  dashboardPaths,
  BANK_DIRECT_SEND_OPTIONS,
  doNothing,
  getMerchantServicePackages,
  setClientCookie,
  destroyClientCookie,
  parseClientCookies,
  parseServerCookies,
  UNAUTHENTICATED_REQUEST_ERRORS,
  updateComponentIfUserAndStateHasNotChanged,
  getCookieFromClient,
  deleteCookie,
  getQueryParameters,
  pushToPaymentPage,
  mapFieldsWithBooleanStringsToProperBooleans,
  mapFieldsWithBooleansToStrings,
  
};
