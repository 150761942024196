import axios from 'axios';
import qs from 'qs';
import useBugsnag from 'hooks/useBugsnag';
const { notifyBugsnag } = useBugsnag();

const ENDPOINT = `${process.env.EP_URL}/client_api/bank.php`;

axios.defaults.withCredentials = true;

const getAll = async () => {
  const requestPayload = {
    request: 'search_bank',
    search_field: 'supports_gip',
    search_value: 'TRUE',
  };

  /*
  const requestPayload = {
    request: 'search_banks',
    search_field: 'all',
    search_value: 'all',
    api:"4.0"
  };
  */

  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload, { arrayFormat: 'brackets' }),
    withCredentials: true,
    url: ENDPOINT,
  };

  return await axios(requestOptions)
    .then((response) => {
      if (response.data.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response } } });
        return error;
      }
      console.table(response.data.output);
      return response.data.output;
    })
    .catch((error) => {
      notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response: error.response } } });
      return error;
    });
};

export default {
  getAll,
};
