const { WEBSITE_URL } = process.env;
const IMAGE_URL = `${WEBSITE_URL}/images/v2`;

const IS_DEV_MODE = process.env.NODE_ENV === 'development';
const IS_CLIENT_MODE = typeof window !== "undefined";


let SONGBIRD_URL = process.env.SONGBIRD_URL;

export { WEBSITE_URL, IMAGE_URL, IS_DEV_MODE, IS_CLIENT_MODE, SONGBIRD_URL };
