import axios from 'axios';
import qs from 'qs';
import useBugsnag from 'hooks/useBugsnag';

const { notifyBugsnag } = useBugsnag();

const ENDPOINT = process.env.EP_URL + '/client_api/referral.php';
axios.defaults.withCredentials = true;
const getReferralChannels = async () => {
  const requestPayload = {
    request: 'referral_channels',
    display_app: 'true',
    display_web: 'true',
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload),
    url: ENDPOINT,
  };

  return await axios(requestOptions)
    .then(function (response) {
      if (response.data.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response } } });
      return error;


      } else {
        const { output } = response.data;
        const channels = output.map((item) => ({
          value: item.referral_code,
          label: item.display_name,
        }));
        return [{ label: 'Friend', value: 'friend' }, ...channels];
      }
    })
    .catch(function (error) {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response: error.response } } });
      return error;


    });
};

export default {
  getReferralChannels,
};
