import moment from "moment";

export function* todayStartGen() {
  while (true) {
    yield moment().startOf('day');
  }
}
export function* yearStartGen() {
  while (true) {
    yield moment().startOf('year');
  }
}

export function* monthStartGen() {
  while (true) {
    yield moment().startOf('month');
  }
}

export function* weekStartGen() {
  while (true) {
    yield moment().startOf('week');
  }
}

export function* rightNowGen() {
  while (true) {
    yield moment().toDate();
  }
}

export function* nullGen() {
  while(true) {
    yield undefined;
  }
}

export function* pastDateGen(value, unit) {
  while (true) {
    yield moment()
      .subtract(value, unit)
      .toDate();
  }
}