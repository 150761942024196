import { parseCookies } from 'nookies';
import { useEffect, useMemo, useState } from 'react';

function useInitUser(deps = []) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

    return () => setMounted(false);
  }, []);

  const user = useMemo(() => {
    const userJson = parseCookies().ep_usr;
    if (!mounted || !userJson) {
      return null;
    }
    return JSON.parse(userJson);
  }, [mounted, ...deps]);

  return user;
}

export default useInitUser;
