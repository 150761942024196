/**
 * function to remove the timeouts that countdown to a session timeout
 *
 * @param showAlertTimeout the timeout for showing an alert to the user
 * @param endSessionTimeout the timeout for ending a user session
 *
 *
 */
export default function deactivateTimeoutCountdown({ showAlertTimeout, endSessionTimeout }) {
  clearTimeout(showAlertTimeout);
  clearTimeout(endSessionTimeout);
}
