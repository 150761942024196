import axios from 'axios';
import qs from 'qs';
import FormData from 'form-data';
import useBugsnag from 'hooks/useBugsnag';

const ENDPOINT = `${process.env.EP_URL}/client_api/payment_option.php`;
const APP_ENDPOINT = `${process.env.EP_URL}/app/v4.1/payment.php`;
const MERCHANT_API_ENDPOINT = `${process.env.EP_URL}/client_api/merchant_api.php`;

axios.defaults.withCredentials = true;
const { notifyBugsnag } = useBugsnag();

const fetchPaymentOptionTypes = async () => {
  const requestPayload = {
    request: 'get_payment_option_type',
    search_field: 'all',
    search_value: '',
    current_page: 0,
    results_per_page: 100,
    sort_by: 'id',
    sort_ascending: 0,
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload, { arrayFormat: 'brackets' }),
    withCredentials: true,
    url: ENDPOINT,
  };

  return await axios(requestOptions)
    .then((response) => {
      if (response.data.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response } } });
        return error;
      }
      return response.data.output;
    })
    .catch((error) => {
      notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response: error.response } } });
      return error;
    });
};

const fetchPaymentOptions = async (token) => {
  const requestPayload = {
    request: 'search_payment_option',
    search_field: 'all',
    search_value: '',
    current_page: 0,
    results_per_page: 100,
    sort_by: 'id',
    sort_ascending: 0,
    api_token: token,
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload, { arrayFormat: 'brackets' }),
    withCredentials: true,
    url: ENDPOINT,
    timeout: 10e3,
  };

  return await axios(requestOptions)
    .then((response) => {
      if (response.data.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response } } });
        return error;
      }

      return response.data.output;
    })
    .catch((error) => {
      notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response: error.response } } });
      return error;
    });
};

const fetchPaymentOptionProcessingFees = async (params) => {
  // const { paymentOptionType, data } = params;
  // const {
  //   merchantServiceSrvrtid,
  //   accountNumber,
  //   transactionAmount,
  //   transactionCurrency,
  //   serviceDescription,
  //   denomSrvrtid,
  //   paymentOptionSrvrtid,
  // } = data;

  const requestPayload = {
    request: 'get_payment_option_processing_fees',
    merchant_service_srvrtid: params.merchantServiceSrvrtid,
    payment_option_srvrtid: params.paymentOptionSrvrtid,
    payacct: params.accountNumber,
    trnamt: params.transactionAmount || '',
    trnamt_ccy: params.transactionCurrency || '',
    service_description: params.serviceDescription || '',
    denom_srvrtid: params.denominationSrvrtid || '',
    ccdefault_pay: 'FALSE',
    ccdefault_transfer: 'FALSE',
    api_token: params.token,
  };

  if (params.ccToken) {
    requestPayload.cctoken = params.ccToken;
    requestPayload.ccsave = 'FALSE';
  } else if (params.isNewCard) {
    requestPayload.firstname = params.firstName;
    requestPayload.lastname = params.lastName;
    requestPayload.ccnumber = params.cardNumber;
    requestPayload.ccexp = params.cardExpiry;
    requestPayload.cvv = params.cardCvv;
    requestPayload.ccsave = params.saveCard ? 'TRUE' : 'FALSE';
    requestPayload.ccdefault_pay = 'FALSE';
    requestPayload.ccdefault_transfer = 'FALSE';
  } else if (params.isNewMobileMoney) {
    requestPayload.ccnumber = params.phoneNumber;
    requestPayload.cvv = params.token || '';
  }

  if (params.additionaldata_line1) {
    requestPayload.additionaldata_line1 = params.additionaldata_line1;
  }

  if (params.additionaldata_line2) {
    requestPayload.additionaldata_line2 = params.additionaldata_line2;
  }

  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload, { arrayFormat: 'brackets' }),
    withCredentials: true,
    url: ENDPOINT,
  };

  return await axios(requestOptions)
    .then((response) => {
      if (response.data.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response } } });
        return error;
      }
      return response.data;
    })
    .catch((error) => {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response: error.response } } });
      return error;
    });
};

const getClientTID = () => {
  const length = 32;
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = length; i > 0; --i) {
    result += chars[Math.round(Math.random() * (chars.length - 1))];
  }
  return result;
};

const fetchPaymentOptionsFromVault = async (userid) => {
  const requestPayload = {
    type: 'vault-lookup',
    clientuid: userid,
    api: '4.1',
    clienttid: getClientTID(),
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload, { arrayFormat: 'brackets' }),
    withCredentials: true,
    url: APP_ENDPOINT,
  };

  return await axios(requestOptions)
    .then((response) => {
      if (response.data.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response } } });
        return error;
      }
      return response.data.cards;
    })
    .catch((error) => {
      notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response: error.response } } });
      return error;
    });
};

const save = async (params) => {
  const requestPayload = {
    request: 'vault-save',
    api: '4.1',
    cvv: '',
    ccdesc: '',
    'ccdefault-pay': 'FALSE',
    'ccdefault-transfer': 'FALSE',
    'paymentoption-srvrtid': '',
  };

  // if ($api < "4.0") {
  //   $ccdefault = get_var($request,"ccdefault");
  //   $ccdefault_transfer = isset($ccdefault)  ? strtoupper($ccdefault) : "FALSE"; //legacy "true"
  //   $ccdefault_pay = $ccdefault_transfer;
  // }else {
  //   $ccdefault_pay = isset($ccdefault_pay) ? $ccdefault_pay : "FALSE";
  //   $ccdefault_transfer = isset($ccdefault_transfer) ? $ccdefault_transfer : "FALSE";
  // }

  // $card->ccdesc = $ccname;
  // $card->ccdefault_transfer = $ccdefault_transfer;
  // $card->ccdefault_pay = $ccdefault_pay;
  // $card->paymentoption_srvrtid = $paymentOptionSrvrtid;
  // $card->cvv2 =  get_var($request,"cvv");

  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload, { arrayFormat: 'brackets' }),
    withCredentials: true,
    url: APP_ENDPOINT,
  };

  return await axios(requestOptions)
    .then((response) => {
      if (response.data.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST' } });
        return error;
      }
      return response.data.output;
    })
    .catch((error) => {
      notifyBugsnag({ error, metaData: { scope: 'API REQUEST' } });
      return error;
    });
};

const saveCard = async ({
  firstName,
  lastName,
  cardNumber,
  cardCvv,
  cardExpiry,
  cardDescription,
  isDefaultForPayments,
  isDefaultForTransfers,
  paymentOptionSrvrtid,
  userEmail,
}) => {
  const requestPayload = {
    type: 'vault-save',
    api: '4.1',
    cvv: cardCvv,
    ccdesc: cardDescription,
    ccnumber: cardNumber,
    ccexp: cardExpiry,
    firstname: firstName,
    lastname: lastName,
    'ccdefault-pay': isDefaultForPayments ? 'TRUE' : 'FALSE',
    'ccdefault-transfer': isDefaultForTransfers ? 'TRUE' : 'FALSE',
    'paymentoption-srvrtid': paymentOptionSrvrtid,
    clientuid: userEmail,
    clienttid: getClientTID(),
  };

  // $ccnumber = get_var($request,"ccnumber");
  // $ccexp = get_var($request,"ccexp");
  // $firstname = get_var($request,"firstname");
  // $lastname = get_var($request,"lastname");

  // if ($api < "4.0") {
  //   $ccdefault = get_var($request,"ccdefault");
  //   $ccdefault_transfer = isset($ccdefault)  ? strtoupper($ccdefault) : "FALSE"; //legacy "true"
  //   $ccdefault_pay = $ccdefault_transfer;
  // }else {
  //   $ccdefault_pay = isset($ccdefault_pay) ? $ccdefault_pay : "FALSE";
  //   $ccdefault_transfer = isset($ccdefault_transfer) ? $ccdefault_transfer : "FALSE";
  // }

  // $card->ccdesc = $ccname;
  // $card->ccdefault_transfer = $ccdefault_transfer;
  // $card->ccdefault_pay = $ccdefault_pay;
  // $card->paymentoption_srvrtid = $paymentOptionSrvrtid;
  // $card->cvv2 =  get_var($request,"cvv");

  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload, { arrayFormat: 'brackets' }),
    withCredentials: true,
    url: APP_ENDPOINT,
  };

  return await axios(requestOptions)
    .then((response) => {
      if (response.data.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST' } });
        return error;
      }
      return response.data.output;
    })
    .catch((error) => {
      notifyBugsnag({ error, metaData: { scope: 'API REQUEST' } });
      return error;
    });
};

const saveMobileMoneyNumber = async ({
  phoneNumber,
  phoneNumberDescription,
  token,
  isDefaultForPayments,
  isDefaultForTransfers,
  paymentOptionSrvrtid,
  userEmail,
}) => {
  const requestPayload = {
    type: 'vault-save',
    api: '4.1',
    cvv: token || "",
    ccdesc: phoneNumberDescription,
    ccnumber: phoneNumber,
    ccexp: '',
    firstname: '',
    lastname: '',
    'ccdefault-pay': isDefaultForPayments ? 'TRUE' : 'FALSE',
    'ccdefault-transfer': isDefaultForTransfers ? 'TRUE' : 'FALSE',
    'paymentoption-srvrtid': paymentOptionSrvrtid,
    clientuid: userEmail,
    clienttid: getClientTID(),
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload, { arrayFormat: 'brackets' }),
    withCredentials: true,
    url: APP_ENDPOINT,
  };

  return await axios(requestOptions)
    .then((response) => {
      if (response.data.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST' } });
        return error;
      }
      return response.data.output;
    })
    .catch((error) => {
      notifyBugsnag({ error, metaData: { scope: 'API REQUEST' } });
      return error;
    });
};

const deleteSavedCard = async ({ token, email }) => {
  const requestPayload = {
    type: 'vault-delete',
    api: '4.1',
    cctoken: token,
    clientuid: email,
    clienttid: getClientTID(),
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload, { arrayFormat: 'brackets' }),
    withCredentials: true,
    url: APP_ENDPOINT,
  };

  return await axios(requestOptions)
    .then((response) => {
      if (response.data.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response } } });
        return error;
      }
      return response.data;
    })
    .catch((error) => {
      notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response: error.response } } });
      return error;
    });
};

const updateCard = async ({ token, description, email, isDefaultForPayments, isDefaultForTransfers }) => {
  const requestPayload = {
    type: 'vault-update',
    api: '4.1',
    cctoken: token,
    ccdesc: description,
    'ccdefault-pay': isDefaultForPayments ? 'TRUE' : 'FALSE',
    'ccdefault-transfer': isDefaultForTransfers ? 'TRUE' : 'FALSE',
    clientuid: email,
    clienttid: getClientTID(),
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload, { arrayFormat: 'brackets' }),
    withCredentials: true,
    url: APP_ENDPOINT,
  };

  return await axios(requestOptions)
    .then((response) => {
      if (response.data.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response } } });
        return error;
      }
      return response.data;
    })
    .catch((error) => {
      notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response: error.response } } });
      return error;
    });
};

const getApiTokenDetails = async (token) => {
  // const data = { request: 'get_api_token_details', token };
  const data = { request: 'search_merchant_api_token', token };

  const requestOptions = {
    method: 'POST',
    url: MERCHANT_API_ENDPOINT,
    data: qs.stringify(data, { arrayFormat: 'brackets' }),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    withCredentials: true,
  };

  return await axios(requestOptions)
    .then((response) => {
      // console.log('response -> ', response.data);
      if (response.data.status !== 0) throw new Error(response.data.message);

      // console.log('headers -> ', response.headers['set-cookie']);
      // eslint-disable-next-line prefer-destructuring
      // document.cookie = response.headers['set-cookie'].split(';')[0];
      // console.log('cookies -> ', document.cookie);
      return { ...response.data, sessionCookie: response.headers['set-cookie'] };
    })
    .catch((e) => {
      throw e;
    });
};
const getSignUserWithToken = async (token) => {
  // const data = { request: 'get_api_token_details', token };
  const TEST_API_ENDPOINT = `${process.env.EP_URL}/client_api/signon.php`;
  const data = { request: 'v2_guest_sign_on', api_token: token };

  const requestOptions = {
    method: 'POST',
    url: TEST_API_ENDPOINT,
    data: qs.stringify(data, { arrayFormat: 'brackets' }),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    withCredentials: true,
  };

  return await axios(requestOptions)
    .then((response) => {
      console.log('response -> ', response.data);
      if (response.data.status !== 0) throw new Error(response.data.message);

      // console.log('headers -> ', response.headers['set-cookie']);
      // eslint-disable-next-line prefer-destructuring
      // document.cookie = response.headers['set-cookie'].split(';')[0];
      // console.log('cookies -> ', document.cookie);
      return { ...response.data, sessionCookie: response.headers['set-cookie'] };
    })
    .catch((e) => {
      throw e;
    });
};

export default {
  fetchPaymentOptionTypes,
  fetchPaymentOptions,
  fetchPaymentOptionProcessingFees,
  fetchPaymentOptionsFromVault,
  save,
  saveCard,
  updateCard,
  saveMobileMoneyNumber,
  deleteSavedCard,
  getApiTokenDetails,
  getSignUserWithToken,
};
