import React, {useContext} from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Link from 'next/link';
import css from 'styled-jsx/css';
import ButtonLink from '@components/common/link/ButtonLink';




const { className: pageContentContainerClassName, styles: pageContentContainerStyleOverrides } = css.resolve`
  .container.page_content_container {
    background-color: white;
    position: relative;
    bottom: 50px;
    padding-top: 100px;
    padding: 100px 60px 80px 60px;
  }
  @media (max-width: 576px) {
    .container.page_content_container {
      background-color: white;
      position: relative;
      bottom: 0px;
      padding-top: 100px;
      padding: 20px 20px 20px 20px;
    }
  }
`;

function FallBackUI(props) {

  
    
  return (
    <>
    <div className="page about">
      <div className="section hero">
        <Container fluid>
          <Row>
            <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
              <div className="hero_content_wrapper">
                <div className="hero_content">
                  <h5 className="intro">404</h5>
                  <h1 className="headline">Something went wrong..</h1>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="section page_content">
        <Container className={`${pageContentContainerClassName} page_content_container`}>
          <Row>
            <Col style={{ paddingLeft: 0, paddingRight: 0 }} className="text-center">
              <div className="content_column">
                <div className="content_section">
                  <h4 className="section_header"></h4>
                  <p className="section_text">
                  Apologies. Something went wrong. Please click the button below and try again.
                  </p>
                  <ButtonLink text="TAKE ME HOME" styleOverrides={{ fontSize: '16px', minHeight: '20px' }} type="restart" onClick={props.onTakeHome}/>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <style jsx>
        {`
          .hero {
            background-color: #4a4a4a;
            text-align: center;
            color: white;
            font-family: 'Open Sans';
          }
          .hero {
            background-color: #4a4a4a;
            background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
              url(https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1652&q=80);
            background-position: 0% 48%;
            background-repeat: no-repeat;
            background-size: 100%;
            text-align: center;
            color: white;
            font-family: 'Open Sans';
          }
          .hero_content_wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 451px;
          }
          @media (max-width: 576px) {
            // .hero_content_wrapper {
            //   height: 351px;
            // }
          }
          @media (max-width: 576px) {
            .hero {
              background-size: 151%;
              background-position: center center;
            }
            .hero_content_wrapper {
              height: 311px;
            }
          }
          .hero_content {
            width: 35%;
          }
          @media (max-width: 991px) {
            .hero_content {
              width: 65%;
            }
          }
          @media (max-width: 576px) {
            .hero_content {
              width: 90%;
              margin-top: 60px;
            }
          }
          .hero .intro {
            color: #ffffff;
            font-size: 24px;
            font-weight: 600;
            line-height: 10px;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 20px;
          }
          .hero .headline {
            font-size: 34px;
            font-weight: bold;
            line-height: 46px;
            text-align: center;
          }
          @media (max-width: 991px) {
            .hero .headline {
              font-size: 24px;
              line-height: 33px;
            }
            .hero .intro {
              font-size: 15px;
              line-height: 10px;
              margin-bottom: 15px;
            }
          }
          .page_content {
            background-color: white;
            /*box-shadow: 0 1px 0 0 #e8ebef;*/
            margin-top:90px;
          }
          .page_content .content_column {
            width: 70%;
            margin-left: auto;
            margin-right: auto;
          }
          @media (max-width: 576px) {
            .page_content .content_column {
              width: 90%;
            }
          }
          .page_content .content_section {
            margin-bottom: 40px;
          }
          .page_content .section_header {
            color: #3a3a3a;
            font-size: 22px;
            font-weight: 600;
            line-height: 35px;
          }
          .page_content .section_text {
            color: #3a3a3a;
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 0.67px;
            line-height: 28px;
          }
        `}
      </style>
    </div>
    </>
  );
}

export default FallBackUI;
