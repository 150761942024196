const client = require('./request/client');
const MerchantService = require('./merchantService');
const URL = process.env.EP_URL + '/client_api/merchant.php';
import useBugsnag from 'hooks/useBugsnag';

const { notifyBugsnag } = useBugsnag();

const findBySrvrtid = async (srvrtid) => {
  const requestPayload = {
    request: 'search_merchants',
    search_field: ['srvrtid'],
    search_value: [`${srvrtid}`],
  };

  return await client
    .sendRequest(URL, requestPayload)
    .then(function (response) {
      
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response } } });
      return error;


      } else {
        const merchant = response.output[0];

        if (!merchant) {
          throw new Error('Service not found');
        } else {
          return merchant;
        }
      }
    })
    .catch(function (error) {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response: error.response } } });
      return error;


    });
};

const fetchLandingPageServices = async (srvrtid) => {
  return await MerchantService.fetchLandingPageServicesForMerchant(srvrtid);
};

const fetchLandingPageConfig = async (merchantSrvrtid) => {
  const requestPayload = {
    request: 'search_merchant_landing_config',
    merchant_srvrtid: merchantSrvrtid,
  };

  return await client
    .sendRequest(URL, requestPayload)
    .then(function (response) {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response } } });
      return error;


      } else {
        return response.output[0];
      }
    })
    .catch(function (error) {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response: error.response } } });

      throw error;
    });
};

const fetchLandingPageConfigWithShortname = async (shortname) => {
  const requestPayload = {
    request: 'search_merchant_landing_config',
    search_field: 'shortname',
    search_value: shortname
  };

  return await client
    .sendRequest(URL, requestPayload)
    .then(function (response) {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response } } });
      return error;


      } else {
        return response.output[0];
      }
    })
    .catch(function (error) {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response: error.response } } });


      throw error;
    });
};

const fetchAllLandingPageConfig = async () => {
  const requestPayload = {
    request: 'search_merchant_landing_config',
    search_field: 'all',
    search_value: 'true'
  };

  return await client
    .sendRequest(URL, requestPayload)
    .then(function (response) {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response } } });
      return error;


      } else {
        return response.output;
      }
    })
    .catch(function (error) {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response: error.response } } });
      return error;


    });
}

module.exports = {
  findBySrvrtid,
  fetchLandingPageConfig,
  fetchLandingPageConfigWithShortname,
  fetchLandingPageServices,
  fetchAllLandingPageConfig
};
