import axios from 'axios';
import qs from 'qs';
import useBugsnag from 'hooks/useBugsnag';

const { notifyBugsnag } = useBugsnag();

const REFERRAL_ENDPOINT = `${process.env.EP_URL}/client_api/billpay_service.php`;

const doBillInquiry = async ({ merchantServiceSrvrtid, accountNumber, billerid = "" }) => {
  const requestPayload = {
    request: 'bill_inquiry',
    api: '4.0',
    merchant_service_srvrtid: merchantServiceSrvrtid,
    payacct: accountNumber,
    billerid,

  };

  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload),
    withCredentials: true,
    url: REFERRAL_ENDPOINT,
  };

  axios.defaults.withCredentials = true;
  return await axios(requestOptions)
    .then(function (response) {
      if (response.data.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response } } });
        return error;
      }
      return response.data.output;
    })
    .catch(function (error) {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response: error.response } } });
      return error;
    });
};

export default {
  doBillInquiry,
};
