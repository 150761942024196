import axios from 'axios';
import qs from 'qs';

import { pastDateGen, rightNowGen } from 'util/dategen';
import { makeRandomString } from '../util';
import useBugsnag from 'hooks/useBugsnag';

const { notifyBugsnag } = useBugsnag();

const ENDPOINT = process.env.EP_URL + '/client_api/payment_transaction.php';
axios.defaults.withCredentials = true;



const search = async (userId, params) => {
  const requestPayload = {
    request: 'search_my_payment_transactions',
    api: '4.0',
    // search_field: ['payeeid'],
    // search_value: [userId],
    results_per_page: params['itemsPerPage'] || 10,
    current_page: params['currentPage'] || 0,
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload, { arrayFormat: 'brackets' }),
    withCredentials: true,
    url: ENDPOINT,
  };

  return await axios(requestOptions)
    .then(function (response) {
      if (response.data.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response } } });
      return error;


      } else {
        return response.data;
      }
    })
    .catch(function (error) {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response: error.response } } });
      return error;


    });
};

const filter = async (params) => {
  const requestPayload = {
    request: 'search_my_payment_transactions',
    api: '4.0',
    search_field: ['start_date', "end_date"],
    search_value:[params.startDate || pastDateGen(1, 'month').next(), params.endDate ||  rightNowGen().next()],
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload, { arrayFormat: 'brackets' }),
    withCredentials: true,
    url: ENDPOINT,
  };

  return await axios(requestOptions)
    .then(function (response) {
      if (response.data.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response } } });
      return error;


      } else {
        return response.data;
      }
    })
    .catch(function (error) {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response: error.response } } });
      return error;


    });
};
const filterData = async (params) => {
  const requestPayload = {
    request : "search_my_payment_transactions_by_key_word",
    current_page : params.filterCurrentPage|| 0,
    search_value : params.searchValue ? params.searchValue : "",
    results_per_page : 10,
    api : "4.1",
    clienttid :  makeRandomString(20),
    filter_key : params.filterKey, 
    from : params.filterKey === "4" ? `${params.startDate} 00:00:00` : params.startDate || pastDateGen(1, 'month').next(), 
    to : params.filterKey === "4" ? `${params.endDate} 23:59:59` : params.endDate ||  rightNowGen().next(),
  };
  
  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload, { arrayFormat: 'brackets' }),
    withCredentials: true,
    url: ENDPOINT,
  };

  return await axios(requestOptions)
    .then(function (response) {
      if (response.data.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response } } });
      return error;


      } else {
        return response.data;
      }
    })
    .catch(function (error) {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response: error.response } } });
      return error;


    });
};
const esTransactionAnalytics = async (params) => {
  const requestPayload = {
    request : "es_transaction_analytics",
    filter_key : params.filterKey, 

  };

 if (params.filterKey === "4") {
  requestPayload.from = `${params.startDate} 00:00:00`;
  requestPayload.to = `${params.endDate} 23:59:59`;
 }
  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload, { arrayFormat: 'brackets' }),
    withCredentials: true,
    url: ENDPOINT,
  };

  return await axios(requestOptions)
    .then(function (response) {
      if (response.data.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response } } });
      return error;


      } else {
        return response.data;
      }
    })
    .catch(function (error) {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response: error.response } } });
      return error;


    });
};


const archiveMyTransactionHistory = async (transaction_srvrtid, params) => {
  const requestPayload =  { 
    "request" : "archive_my_transaction_history",
    "transaction_srvrtid" : transaction_srvrtid,
    "api" : "4.1",
    "clienttid" : makeRandomString(20)
  }

  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload, { arrayFormat: 'brackets' }),
    withCredentials: true,
    url: ENDPOINT,
  };

  return await axios(requestOptions)
    .then(function (response) {
      if (response.data.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response } } });
      return error;


      } else {
        return response.data;
      }
    })
    .catch(function (error) {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response: error.response } } });
      return error;


    });
};

const exportTransactionHistory = async (params={}) => {
  const requestPayload = {
    request: 'search_my_payment_transactions',
    api: '4.0',
    results_per_page: params['itemsPerPage'] || 10,
    current_page: params['currentPage'] || 0,
    send_email: "1",
    filter_key: params['filterKey'] || '6',
  };

  if (params.filterKey === "4") {
    requestPayload.from = `${params.startDate} 00:00:00`;
    requestPayload.to = `${params.endDate} 23:59:59`;
   }



  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload, { arrayFormat: 'brackets' }),
    withCredentials: true,
    url: ENDPOINT,
  };

  return await axios(requestOptions)
    .then(function (response) {
      if (response.data.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response } } });
      return error;


      } else {
        return response.data;
      }
    })
    .catch(function (error) {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response: error.response } } });
      return error;


    });
};




export default {
  search,
  filter,
  filterData,
  esTransactionAnalytics,
  archiveMyTransactionHistory,
  exportTransactionHistory
};
