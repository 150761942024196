const client = require('./request/client');
const URL = process.env.EP_URL + '/client_api/merchant_api.php';
import useBugsnag from 'hooks/useBugsnag';

const { notifyBugsnag } = useBugsnag();

const searchMerchantApiToken = async (token) => {
  const requestPayload = {
    request: 'search_merchant_api_token',
    token,
  };

  return await client
    .sendRequest(URL, requestPayload)
    .then(function (response) {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST' } });

        throw error;
      } else {
        return response.request;
      }
    })
    .catch(function (error) {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST' } });


      throw error;
    });
};

module.exports = {
  searchMerchantApiToken,
};
