/**
 * function to set up timeout resets whenever the user performs some keyboard or mouse actions
 *
 * @param showAlertTimeout timeout object that controls when / whether to show the timeout alert to the user
 *
 * @param endSessionTimeout timeout object that controls when / whether to end the user's session
 */
export default function setupTimeoutResetsOnUserActivity(params = {}) {
  // clear the current timeouts, and reinitialize them, when any of the user actions are performed
  const { setWindowEventListeners = () => {}, resetTimeout = () => {} } = params;

  const windowEvents = ['click', 'mousemove', 'keypress', 'scroll', 'mousedown'];

  windowEvents.forEach((each) => {
    document.addEventListener(each, resetTimeout);
  });
  setWindowEventListeners(windowEvents);
}
