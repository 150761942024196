const axios = require('axios');
const qs = require('qs');

axios.defaults.withCredentials = true;

const sendRequest = async (url, payload = {}) => {
  return await axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(payload, { arrayFormat: 'brackets' }),
    withCredentials: true,
    url,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

const uploadFile = async (url, fields) => {
  var formData = new FormData();

  for (let fieldName in fields) {
    formData.set(fieldName, fields[fieldName]);
  }

  return await axios
    .post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

module.exports = {
  sendRequest,
  uploadFile,
};
