import useBugsnag from 'hooks/useBugsnag';

const client = require('./request/client');
const { notifyBugsnag } = useBugsnag();

const URL = `${process.env.EP_URL}/client_api/category_ad.php`;

const fetchExcluding = async (categoryId) => {
  const requestPayload = {
    request: 'fetch_category_ad',
    category_id: categoryId,
  };

  return await client
    .sendRequest(URL, requestPayload)
    .then((response) => {
      console.log('response from categoryAd::', response)
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response } } });
      return error;


        return [];
      }
      console.log('response.output from categoryAd::', response.output)
      return response.output;
    })
    .catch((error) => {
      console.log('error from categoryAd::', error)
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response: error.response } } });
      return error;


    });
};

module.exports = {
  fetchExcluding,
};
