/* eslint-disable no-param-reassign */
/* eslint-disable no-else-return */
/* eslint-disable consistent-return */

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

let shouldSendToBugsnag = process.env.APP_PROFILE == "production" || process.env.APP_PROFILE == "staging" 
// initiates bugsnag
const start = () => {
	if (shouldSendToBugsnag) {
		return Bugsnag.start({
			releaseStage:process.env.APP_PROFILE,
			apiKey: process.env.BUG_SNAG_API_KEY,
			plugins: [new BugsnagPluginReact()],
		});
	}
}

// send error to bugsnag
const notifyBugsnag = ({ error, metaData = {} }) => {
	if (shouldSendToBugsnag) {
		// detect native Error class or similar variants of it
		const isError = error instanceof Error || ('name' in error && 'message' in error && 'stack' in error);

		if (isError) {
			return Bugsnag.notify(error, event => {
				event.context = error.message;

				// attach metaData (more details)
				Object.entries(metaData).map(([key, value]) => event.addMetadata(key, { value }));
			});
		} else {
			return Bugsnag.notify(new Error('Non-standard Error Object, see attached metadata'), event => {
				// add message if available else add the name
				if ('message' in Error) event.context = error.message;
				else if ('name' in Error) event.context = error.name;

				// attach metaData (more details)
				Object.entries(metaData).map(([key, value]) => event.addMetadata(key, { value }));
			});
		}
	}
}


const useBugsnag = () => {
	return {
		start,
		notifyBugsnag,
	};
}

export default useBugsnag;
