// import { ErrorPage } from '@components/screens';
import React from 'react';
import { withRouter } from "next/router";
import FallBackUI from "../screens/FallBackUI";
import useBugsnag from 'hooks/useBugsnag';

class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    console.log('error::', error);
    console.log('errorInfo::', errorInfo);

    const { notifyBugsnag } = useBugsnag();

    notifyBugsnag({
      error,
      metaData: {
        errorInfo,
      }
    });
  }

  handleTakeHome=()=>{
    this.setState({ hasError: false });
    this.props.router.push("/");
  }

  render() {
    return (
      <>
        {this.state.hasError ? (
          <>
              <FallBackUI onTakeHome={this.handleTakeHome} />
          </>
        ) : (
          this.props.children
        )}
      </>
    );
  }
}


export default withRouter(AppErrorBoundary);


