import axios from 'axios';
import qs from 'qs';
import useBugsnag from 'hooks/useBugsnag';

const { notifyBugsnag } = useBugsnag();

const ENDPOINT = `${process.env.EP_URL}/app/v4.1/payment.php`;
const doAuthSale = async (params) => {
  const requestPayload = {
    type: 'authsale',
    'paymentoption-processingtoken': params.paymentOptionProcessingToken,
    'ccy-srvrtid': params.currencySrvrtid,
    clienttid: params.clientTID,
    clientuid: params.clientUID,
    api: '4.1',
  };

  if (params.mmVerifyToken) requestPayload['mm_verify_token'] = params.mmVerifyToken;

  if (params.sessionId) {
    requestPayload['cc3ds_sessionid'] = params.sessionId;
  }

  if (params.cc3dsTransactionId) {
    requestPayload['cc3ds_transaction_id'] = params.cc3dsTransactionId;
  }

  if (params.apiToken) {
    requestPayload['api-token'] = params.apiToken;
  }

  if (params.confirm) {
    requestPayload.confirm = params.confirm;
  }
  if (params.cvv) {
    requestPayload.cvv = params.cvv;
  }

  if (params.address) {
    requestPayload.address1 = params.street;
    requestPayload.country = params.country;
    requestPayload.state = params.state;
    requestPayload.city = params.city;
    requestPayload.zip = params.zip;
  }

  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload, { arrayFormat: 'brackets' }),
    withCredentials: true,
    url: ENDPOINT,
  };

  axios.default.withCredentials = true;
  return await axios(requestOptions)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST' } });

      return error;
    });
};

export default {
  doAuthSale,
};
