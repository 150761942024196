import React, { useState } from 'react'
import ProfileContext, { initialState } from '.'


function ProfileProvider({ children }) {
  const [profilePicSrc, setProfilePicSrc] = useState(initialState.profilePicSrc);

  const handleProfilePicInit = (src) => {
    console.log('src from handleProfilePicInit::', src)
    if (src === initialState.profilePicSrc) return;
    if (src.trim()) setProfilePicSrc(src);
  }

  return (
    <ProfileContext.Provider value={{ profilePicSrc, setProfilePicSrc: handleProfilePicInit }}>{children}</ProfileContext.Provider>
  )
}

export default ProfileProvider