import axios from 'axios';
import qs from 'qs';
import useBugsnag from 'hooks/useBugsnag';

const { notifyBugsnag } = useBugsnag();

const ENDPOINT = process.env.EP_URL + '/client_api/card_verification.php';

const verifyCard = async ({ debitOne, debitTwo, token, srvrtid }) => {
  const requestPayload = {
    request: 'card_verification',
    auth1: debitOne,
    auth2: debitTwo,
    token,
    srvrtid,
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload, { arrayFormat: 'brackets' }),
    withCredentials: true,
    url: ENDPOINT,
  };

  axios.defaults.withCredentials = true;
  return await axios(requestOptions)
    .then(function (response) {
      if (response.data.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response } } });
      return error;
      } else {
        return response.data;
      }
    })
    .catch(function (error) {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response: error.response } } });
      return error;
    });
};

const getCardTransaction = async ({ user_srvrtid, card_verification_srvrtid, card_verification_token }) => {
  const requestPayload = {
    request: 'get_card_transaction',
    user_srvrtid,
    card_verification_srvrtid,
    card_verification_token,
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload, { arrayFormat: 'brackets' }),
    withCredentials: true,
    url: ENDPOINT,
  };

  axios.defaults.withCredentials = true;
  return await axios(requestOptions)
    .then(function (response) {
      if (response.data.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response } } });
      return error;
      } else {
        return response.data;
      }
    })
    .catch(function (error) {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response: error.response } } });
      return error;
    });
};


export default {
  verifyCard,
  getCardTransaction,
};
