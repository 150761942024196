import axios from 'axios';
import qs from 'qs';
import useBugsnag from 'hooks/useBugsnag';

const { notifyBugsnag } = useBugsnag();

const REFERRAL_ENDPOINT = process.env.EP_URL + '/client_api/contact.php';

const sendMessage = async ({ name, email, subject, message }) => {
  const requestPayload = {
    request: 'contact_us',
    send: 'Submit',
    name,
    email,
    subject,
    message,
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestPayload, { arrayFormat: 'brackets' }),
    url: REFERRAL_ENDPOINT,
  };

  return await axios(requestOptions)
    .then(function (response) {
      if (response.data.status !== 0) {
        const error = new Error(response.data.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response } } });
      return error;


      } else {
        return response.data.message;
      }
    })
    .catch(function (error) {
      console.log('contact error::', error);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestOptions, response: error.response } } });
      return error;


    });
};

export default {
  sendMessage,
};
