import Router from 'next/router';
import { toast } from 'react-toastify';
export const redirectUser = (ctx, location) => {
  if (ctx.req) {
    ctx.res.writeHead(302, { Location: location });
    ctx.res.end();
  } else {
    Router.push(location);
  }
};

export const redirectTo = (msg, location) => {
  toast(msg, {
    className: 'success-toast',
  });
  setTimeout(() => {
    Router.push(location);
  }, 1000);
};
