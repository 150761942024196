/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StoreContext } from 'pages/_app';

function SessionTimeoutAlert(props) {
  const [countDownSecs, setCountDownSecs] = useState(process.env.NUM_SECS_TO_SHOW_TIMEOUT_ALERT || 20);
  const intervalRef = useRef();
  const { onClick = () => {} } = props;
  const { logout } = useContext(StoreContext);

  useEffect(() => {
    const interval = setInterval(() => setCountDownSecs((prev) => parseInt(prev, 10) - 1), 1000);
    intervalRef.current = interval;

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (countDownSecs > 0) return;
    logout();
    clearInterval(intervalRef.current);
  }, [countDownSecs]);

  return (
    <SessionTimeoutContainer
      onClick={() => {
        onClick();
      }}
    >
      <img src="./img/alert.svg" alt="alert" style={{ marginRight: '30px' }} id="image" />

      <div style={{ textAlign: 'left', marginRight: '10px' }} id="text">
        For security purposes, you will be logged out of your account in {countDownSecs} seconds due to inactivity.
        <br />
        Click this banner to indicate that you are still active.
      </div>
      <div
        style={{
          background: 'white',
          borderRadius: '30px',
          boxShadow: '0px 23px 44px #3F5F7C5A',
          WebkitBoxShadow: '0px 23px 44px #3F5F7C5A',
          color: '#3A3A3A',
          padding: '10px',
          minWidth: '80px',
          cursor: 'pointer',
        }}
        id="button"
      >
        I'M STILL ACTIVE
      </div>
    </SessionTimeoutContainer>
  );
}

const SessionTimeoutContainer = styled.div`
  padding: 30px;
  background: #f05365;
  min-height: 100px;
  position: fixed;
  left: 30px;
  right: 30px;
  bottom: 30px;
  color: white;
  text-align: center;
  font-weight: 600;
  z-index: 1000;
  border-radius: 7px;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    bottom: 10px;
    left: 10px;
    right: 10px;
    align-items: flex-start;

    #button {
      width: 100%;
      margin-left: 0;
    }

    #image {
      margin-bottom: 20px;
    }

    #text {
      margin-bottom: 20px;
    }
  }
`;

SessionTimeoutAlert.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SessionTimeoutAlert;
