// const ENDPOINT = process.env.EP_URL + '/client_api/user_service_account.php';
import axios from 'axios';
import useBugsnag from 'hooks/useBugsnag';

const qs = require('qs');
const client = require('./request/client');
const { notifyBugsnag } = useBugsnag();

const URL = `${process.env.EP_URL}/client_api/user_service_account.php`;

const filterByUserAndMerchantService = async ({ userSrvrtid, merchantServiceSrvrtid, status,  payAccount}) => {
  const requestPayload = {
    request: 'search_user_service_account',
    search_field: ['merchant_service_srvrtid'],
    search_value: [merchantServiceSrvrtid]
  };

  if(status){
    requestPayload.search_field.push('status')
    requestPayload.search_value.push(status)
  }

  if(userSrvrtid){
    requestPayload.search_field.push('user_srvrtid')
    requestPayload.search_value.push(userSrvrtid)
  }
  if(payAccount){
    requestPayload.search_field.push('account_number')
    requestPayload.search_value.push(payAccount)
  }
 

  return await client
    .sendRequest(URL, requestPayload)
    .then(function (response) {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response } } });
      return error;
      }
      return response.output;
    })
    .catch(function (error) {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response: error.response } } });
      return error;
    });
};

const filterByUser = async ({ userSrvrtid, params = {} }) => {
  const { sortOrder = 'desc' } = params;

  const requestPayload = {
    request: 'search_user_service_account',
    search_field: ['user_srvrtid', 'status'],
    search_value: [userSrvrtid, 'ACTIVE'],
    results_per_page: params.itemsPerPage || 10,
    current_page: params.currentPage || 0,
    sort_by: params.sortBy || 'timestamp',
    sort_ascending: sortOrder === 'asc' ? 1 : 0,
  };

  return await client
    .sendRequest(URL, requestPayload)
    .then((response) => {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response } } });
      return error;
      }

      return response;
    })
    .catch((error) => {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response: error.response } } });
      return error;
    });
};

const filterBySrvrtid = async (srvrtid) => {
  const requestPayload = {
    request: 'search_user_service_account',
    search_field: ['srvrtid'],
    search_value: [srvrtid],
  };

  return await client
    .sendRequest(URL, requestPayload)
    .then(function (response) {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response } } });
      return error;
      }
      return response;
    })
    .catch(function (error) {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response: error.response } } });
      return error;
    });
};

const remove = async (srvrtid) => {
  const requestPayload = {
    request: 'delete_user_service_account',
    srvrtid,
  };

  return await client
    .sendRequest(URL, requestPayload)
    .then(function (response) {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response} } });
      return error;
      }
      return response;
    })
    .catch(function (error) {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response: error.response } } });
      return error;
    });
};

const create = async ({
  accountNumber,
  description,
  merchantServiceSrvrtid,
  userSrvrtid,
  bankBranchSrvrtid,
  saveAsFavorite,
}) => {
  const requestPayload = {
    request: 'create_user_service_account',
    account_number: accountNumber,
    description,
    merchant_service_srvrtid: merchantServiceSrvrtid,
    user_srvrtid: userSrvrtid,
    mine: 'FALSE',
    favorite: saveAsFavorite ? 'TRUE' : 'FALSE',
    save: 'TRUE',
    bank_branch_srvrtid: bankBranchSrvrtid,
  };

  return await client
    .sendRequest(URL, requestPayload)
    .then(function (response) {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response} } });
      return error;
      }
      return response;
    })
    .catch(function (error) {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response: error.response } } });
      return error;
    });
};

const edit = async ({ srvrtid, accountNumber, description, merchantServiceSrvrtid }) => {
  const requestPayload = {
    request: 'edit_user_service_account',
    srvrtid,
    description,
    account_number: accountNumber,
    merchant_service_srvrtid: merchantServiceSrvrtid,
  };

  return await client
    .sendRequest(URL, requestPayload)
    .then(function (response) {
      if (response.status !== 0) {
        const error = new Error(response.message);
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response} } });
      return error;
      }
      return response;
    })
    .catch(function (error) {
        notifyBugsnag({ error, metaData: { scope: 'API REQUEST', xhr_data: { request: requestPayload, response: error.response } } });
      return error;
    });
};

module.exports = {
  filterBySrvrtid,
  filterByUser,
  filterByUserAndMerchantService,
  remove,
  create,
  edit,
};
